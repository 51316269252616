.epic-border {
  border: 1px solid #a335ee;
}

.uncommon-border {
  border: 1px solid #1eff00;
}

.common-border {
  border: 1px solid white;
}

.rare-border{
  border: 1px solid #0070dd;
}

.poor-border {
  border: 1px solid #9d9d9d;
}

.legendary-border {
  border: 1px solid #ff8000;
}